<template>
  <v-dialog
    v-model="dialogCurtidas"
    @click:outside="$emit('update:dialogCurtidas', false)"
    @keydown.esc="$emit('update:dialogCurtidas', false)"
    max-width="1200px"
  >
    <DialogPostCurtida
      v-if="dialogPostCurtida"
      :dialogPostCurtida.sync="dialogPostCurtida"
      :post_id="post_id"
    />
    <div class="d-flex accent justify-center align-center px-4 py-4">
      <span class="text-h5  ">Todos os Posts</span>
      <v-spacer></v-spacer>
      <v-btn text fab small @click="$emit('update:dialogCurtidas', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-card tile class="py-6 px-2">
      <v-card-title class="align-end">
        <v-text-field
          style="max-width: 400px;"
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          clearable
          dense
          hide-details
        ></v-text-field>

        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="posts"
        :loading="loading"
        :items-per-page="10"
        :sort-desc="true"
        class="data-tables data-tables__row-click"
        @click:row="openPost"
      >
        <template v-slot:item.conteudo="{ item }">
          {{ item.conteudo | excerpt(90) }}
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | dateFormat("dd/MM/yyyy HH:mm") }}
        </template>

        <template v-slot:item.qtde="{ item }">
          {{ item.qtde }}
          <v-icon right>mdi-cards-heart</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import { allPostsCurtidas } from "@/api/admin/graficos/posts.js";
export default {
  name: "dialogCurtidas",

  props: {
    dialogCurtidas: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      posts: [],
      search: "",
      loading: false,
      dialogPostCurtida: false,
      post_id: null,
    };
  },

  components: {
    DialogPostCurtida: () => import("./DialogPostCurtida.vue"),
  },

  computed: {
    headers() {
      return [
        {
          text: "Nome",
          value: "nome",
        },
        {
          text: "Conteudo",
          value: "conteudo",
        },
        {
          text: "Data Post",
          value: "created_at",
        },
        {
          text: "Curtidas",
          value: "qtde",
        },
      ];
    },
  },

  methods: {
    getAllPostsCurtidas() {
      this.loading = true;
      allPostsCurtidas()
        .then((response) => {
          this.posts = response;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    openPost(item) {
      this.dialogPostCurtida = true;
      this.post_id = item.id;
    },
  },

  mounted() {
    this.getAllPostsCurtidas();
  },
};
</script>

<style></style>
