import api from "@/api/axios_service.js";

export async function postsTotal(filtros) {
  const response = await api.get(
    `/graficos/posts-total${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function allPostsCurtidas(filtros) {
  const response = await api.get(
    `/graficos/todos-posts-curtidas${filtros ? filtros : ""}`
  );
  return response.data.result;
}
